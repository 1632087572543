import React from 'react';
import styled from '@emotion/styled';
import css from '@emotion/css';

import Certificate from 'Icons/Certificate';
import CertificateIneligible from 'Icons/CertificateIneligible';

import { Copy } from 'Styles/typography';
import colors from 'Utils/theme';
import { phoneFormatter } from 'Utils';

import Homebase from 'Icons/Homebase';

const StyledAddressCard = styled.div`
  position: relative;
  display: flex;
  background-clip: padding-box;
  flex-direction: ${(props) => (props.inCta ? 'row' : 'column')};
  align-items: ${(props) => (props.inCta ? 'flex-start' : 'center')};
  background: ${(props) => (props.inCta ? 'none' : colors.white)};
  border-radius: 14px;
  padding: ${(props) => (props.inCta ? '10px' : '20px')};
  height: 100%;
  transition: border 300ms;
  z-index: 1;
  cursor: ${(props) => (props.eligible && !props.reactivate ? 'pointer' : 'default')};
  border: ${(props) => (props.inCta ? '0' : props.selected || props.reactivate ? (props.partnership === 'lennar' ? `2px solid ${colors.inHome.lennarBlue}` : `2px solid ${colors.walmart.blue}`) : `2px solid ${colors.inHome.medGrey}`)};

  ${(props) =>
    props.partnership === 'homebase' &&
    props.selected &&
    css`
      border-color: transparent;

      &::before,
      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
      }

      &::before {
        border-radius: inherit;
        margin: -2px;
        background: linear-gradient(to bottom, ${colors.inHome.homebaseTeal}, ${colors.inHome.homebaseBlue});
      }

      &::after {
        background: ${colors.white};
        border-radius: 13px;
      }
    `}
`;

const StyledEligibilityText = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: normal;
  color: ${(props) => (!props.eligible && props.isInDrawer ? colors.inHome.darkGrey : colors.walmart.blue)};
  margin-left: ${(props) => (props.isInDrawer ? 'auto' : 0)};

  svg {
    width: 15px;
    margin-left: 10px;
    height: auto;
  }
`;

const StyledIcon = styled.span`
  max-height: ${(props) => (props.size ? props.size : '20px')};
  margin-left: 5px;

  svg {
    width: ${(props) => (props.size ? props.size : '20px')};
    height: ${(props) => (props.size ? props.size : '20px')};
    ${(props) => (props.rotate45 ? `transform: rotate(45deg);` : ``)};
  }
`;

const StyledEligibilityTextCheckout = styled(StyledEligibilityText)`
  h4 {
    color: ${colors.walmart.blue};
    font-weight: bold;
    font-size: 16px;
    margin: 5px 0;
  }

  span${StyledIcon} {
    margin-left: 0;
  }

  svg {
    margin-left: 0;
    margin-right: 10px;
    width: 20px;
    height: 20px;
  }
`;

const StyledAddressBlock = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: ${(props) => (props.inCta ? 'center' : 'flex-start')};
  justify-content: flex-start;

  span {
    height: auto;
  }

  &:focus {
    box-shadow: none;
  }
`;

const StyledAddress = styled(Copy)`
  display: ${(props) => (props.inCta ? 'inline' : 'flex')};
  color: ${(props) => (props.eligible ? (props.inCta ? colors.white : colors.walmart.blue) : props.inCta ? colors.inHome.lightGrey : colors.trans.grey)};
  font-weight: ${(props) => (props.inCta && props.selected ? 700 : 400)};

  ${(props) =>
    !props.inCta &&
    css`
      flex-direction: column;

      span {
        &:first-of-type {
          font-weight: bold;
        }
      }
    `}
`;

const StyledCardHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid ${colors.inHome.medGrey};

  label {
    display: flex;
    align-items: center;

    svg {
      margin-left: 5px;
      width: 32px;
      height: 32px;
    }
  }
`;

const StyledIneligibleText = styled.div`
  font-size: 14px;
  padding: 10px 0;
  color: ${colors.inHome.darkGrey};

  h4 {
    font-weight: bold;
    font-size: 16px;
    margin: 5px 0;
  }
`;

const StyledIneligibleTextHeader = styled.div`
  display: flex;
  align-items: center;
  height: auto;

  span${StyledIcon} {
    margin-left: 0;
  }

  svg {
    margin-left: 0;
    margin-right: 10px;
    width: 20px;
    height: 20px;
  }
`;

const StyledCardFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  align-self: flex-start;
  width: 100%;
  padding-top: 0;
  margin-top: 0;
`;

const StyledRadio = styled.div`
  width: 24px;
  height: 24px;
  position: relative;
  border-radius: 100%;
  border: 1px solid ${(props) => (props.eligible ? (props.inCta ? colors.white : props.selected ? colors.walmart.blue : colors.inHome.medGrey) : 'transparent')};
  margin-right: 10px;
  flex: 0 0 24px;

  &::after {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    top: 50%;
    left: 50%;
    margin-left: -5px;
    margin-top: -5px;
    border-radius: 100%;
    background-color: ${(props) => (props.inCta ? colors.white : colors.walmart.blue)};
    opacity: ${(props) => (props.selected ? 1 : 0)};
    transition: opacity 300ms;
  }
`;

const Button = styled.button`
  -webkit-appearance: none;
  border-radius: 0;
  text-decoration: underline;
  cursor: pointer;
`;

const AddressCard = ({ isInDrawer, inCta, handleChange, openModal, selected, address, reactivate }) => {
  const { name, addressLineOne, addressLineTwo, city, state, postalCode, phone, eligible, partnership } = address;

  const isHomebase = partnership === 'homebase';

  return (
    <StyledAddressCard
      tabIndex={0}
      onKeyDown={(e) => {
        const code = e.charCode || e.keyCode;
        if (eligible && (code === 32 || code === 13)) {
          handleChange(address.id);
        }
      }}
      eligible={eligible}
      selected={selected}
      reactivate={reactivate}
      inCta={inCta}
      partnership={partnership && (partnership === 'lennar' ? 'lennar' : 'homebase')}
    >
      {eligible && !isInDrawer && !reactivate && !inCta && (
        <StyledCardHeader>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <StyledRadio selected={selected} eligible={eligible} />
            <label>{selected ? 'Selected' : 'Select'}</label>
          </div>
          {partnership && (
            <div style={{ display: 'flex', alignItems: 'center', marginLeft: 'auto' }}>
              <label style={{ fontSize: '14px', fontWeight: 'bold' }}>{isHomebase ? <Homebase /> : <img src="/static/lennar-logo.png" alt="Lennar" style={{ width: '76px', height: 'auto' }} />}</label>
            </div>
          )}
        </StyledCardHeader>
      )}

      <StyledAddressBlock inCta={inCta}>
        {inCta && <StyledRadio inCta={inCta} selected={selected} eligible={eligible} />}
        <StyledAddress eligible={eligible} inCta={inCta} selected={selected}>
          {!inCta && <span>{name}</span>}
          <span>{`${addressLineOne}${inCta ? ',' : ''}`}</span>
          {addressLineTwo && !inCta && <span>{addressLineTwo}</span>}
          <span>{`${city}, ${state} ${postalCode}`}</span>
          {!inCta && <span>{phoneFormatter(phone)}</span>}
        </StyledAddress>
        {isInDrawer && !inCta && (
          <StyledEligibilityText eligible={eligible} isInDrawer={isInDrawer}>
            {!eligible ? (
              'Not in delivery area'
            ) : (
              <>
                In delivery area <Certificate />
              </>
            )}
          </StyledEligibilityText>
        )}
      </StyledAddressBlock>
      {!eligible && !isInDrawer && !inCta && (
        <StyledIneligibleText>
          <StyledIneligibleTextHeader>
            <StyledIcon>
              <CertificateIneligible />
            </StyledIcon>
            <h4>Not in delivery area</h4>
          </StyledIneligibleTextHeader>
          We can notify you when InHome Delivery is in your neighborhood.
        </StyledIneligibleText>
      )}

      {!isInDrawer && !inCta && (
        <StyledCardFooter>
          {eligible !== null &&
            (eligible ? (
              <StyledEligibilityTextCheckout>
                <StyledIcon>
                  <Certificate />
                </StyledIcon>
                <h4>In delivery area</h4>
              </StyledEligibilityTextCheckout>
            ) : (
              <StyledEligibilityText>
                <Button onClick={(e) => e.preventDefault() + openModal(address.postalCode)}>Notify me</Button>
              </StyledEligibilityText>
            ))}
        </StyledCardFooter>
      )}
    </StyledAddressCard>
  );
};

export default AddressCard;
